#offline {
  text-align: center;
  font-weight: 600;
}
.Container {
  background-color: azure !important;
}
.card {
  border: 4px solid #0097d7;
  padding: 30px 30px;
  border-width: 4px 0px;
  width: fit-content;
}
/* .Parent {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100vh;
} */

.btn-success {
color: #fff;
background-color: #3e3935;
border-color: #3e3935;
}
.btn-success:hover {
color: #fff;
background-color: #0097d7;
border-color: #0097d7;
}
::marker {
color: #0097d7;
}
li#des {
color: #0097d7;
}
span#siteName {
  font-weight: 600;
}
/* span#cont {
color: #f68d2d;
} */
h6.text-left.mt-4.font-weight-bold.mb-0 {
  font-weight: 700;
}
