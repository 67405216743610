.translation-box {
  right: 9px;
  bottom: 5px;
  position: absolute;
  top: 87px;
}

a.pdf {
  /* bottom: 5px; */
  position: absolute;
  right: 9px;
  bottom: 5px;
  font-weight: 800;
  padding: 2px;
  color: #0097d7 !important;
  text-decoration: none !important;
}

a.pdf:hover {
  text-decoration: none;
}

.translator-holder {
  position: relative;
}

@media (min-width: 320px) and (max-width: 767px) {
  .pdf-box {
    background-color: #0097d7;
    padding: 5px;
  }
}
